var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"add-or-edit-quiz"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"add_quiz",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('h6',[_vm._v("Register a Quiz")]),(_vm.errored)?_c('section',[_vm._v(" "+_vm._s(_vm.errInfo)+" ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.error)+" "),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("question: ")]),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('RichTextEditor',{model:{value:(_vm.question),callback:function ($$v) {_vm.question=$$v},expression:"question"}}),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_vm._l((_vm.options),function(option,index){return _c('div',{key:index,staticClass:"form-group"},[_c('label',[_vm._v(" option "+_vm._s(index + 1)+": "),_c('span',{staticClass:"correct_option_span"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedoptions),expression:"selectedoptions"}],attrs:{"type":"checkbox","disabled":(_vm.selectedoptions.length >= _vm.maxSelectableOption && _vm.selectedoptions.indexOf(index) == -1) || option === ''},domProps:{"value":index,"checked":Array.isArray(_vm.selectedoptions)?_vm._i(_vm.selectedoptions,index)>-1:(_vm.selectedoptions)},on:{"click":function($event){return _vm.selectOption($event, index)},"change":function($event){var $$a=_vm.selectedoptions,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=index,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.selectedoptions=$$a.concat([$$v]))}else{$$i>-1&&(_vm.selectedoptions=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.selectedoptions=$$c}}}}),_vm._v(" Correct answer ")]),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.options[index]),expression:"options[index]"}],staticClass:"u-full-width",attrs:{"placeholder":"Enter option"},domProps:{"value":(_vm.options[index])},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.options, index, $event.target.value)}}}),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])}),_c('div',{staticClass:"form-group"},[(_vm.options.length < _vm.optionsMaxLength)?_c('button',{staticClass:"add-option-btn",on:{"click":_vm.addOption}},[_vm._v("+")]):_vm._e(),(_vm.options.length > _vm.optionsMinLength)?_c('button',{staticClass:"del-option-btn",on:{"click":_vm.removeOption}},[_vm._v("-")]):_vm._e()]),_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.allow_multiple_answers),expression:"allow_multiple_answers"}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.allow_multiple_answers)?_vm._i(_vm.allow_multiple_answers,null)>-1:(_vm.allow_multiple_answers)},on:{"click":function($event){return _vm.allowMultipleAns($event)},"change":function($event){var $$a=_vm.allow_multiple_answers,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.allow_multiple_answers=$$a.concat([$$v]))}else{$$i>-1&&(_vm.allow_multiple_answers=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.allow_multiple_answers=$$c}}}}),_vm._v(" Allow selection of multiple answers ")]),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("author: ")]),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.author),expression:"author"}],staticClass:"u-full-width",attrs:{"type":"text","placeholder":"Enter author"},domProps:{"value":(_vm.author)},on:{"input":function($event){if($event.target.composing){ return; }_vm.author=$event.target.value}}}),_c('span',[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("points: ")]),_c('ValidationProvider',{attrs:{"rules":("between:" + _vm.minPoint + "," + _vm.maxPoint + "|required")},scopedSlots:_vm._u([{key:"default",fn:function(v){return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.point),expression:"point"}],attrs:{"type":"number","min":_vm.minPoint,"max":_vm.maxPoint},domProps:{"value":(_vm.point)},on:{"input":function($event){if($event.target.composing){ return; }_vm.point=$event.target.value}}}),_c('span',[_vm._v(" "+_vm._s(v.errors[0])+" ")])]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Correct answer(s) explanation: ")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.explanation),expression:"explanation"}],staticClass:"u-full-width",attrs:{"placeholder":"Correct answer(s) explanation"},domProps:{"value":(_vm.explanation)},on:{"input":function($event){if($event.target.composing){ return; }_vm.explanation=$event.target.value}}})]),_c('button',{staticClass:"add-quiz-btn",attrs:{"type":"submit"}},[_vm._v("Add Quiz")])],2)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }