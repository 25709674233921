<template>
  <div class="add-or-edit-quiz">
    <ValidationObserver v-slot="{ handleSubmit }" >
      <form @submit.prevent="handleSubmit(onSubmit)" class="add_quiz">
        <h6>Register a Quiz</h6>

        <section v-if="errored">
          {{errInfo}}
        </section>

        {{error}}

        <div class="form-group">
            <label>question: </label>
            <ValidationProvider v-slot="{ errors }" rules="required">
              <!-- <textarea class="u-full-width" placeholder="Enter Quiz" v-model="question"></textarea> -->
              <RichTextEditor v-model="question" />
              <span>{{errors[0]}}</span>
            </ValidationProvider>
        </div>

        <div class="form-group" v-for="(option, index) in options" :key="index">
          <label>
            option {{index + 1}}:
            <span class="correct_option_span">
              <input
              type="checkbox"
              :disabled=" (selectedoptions.length >= maxSelectableOption && selectedoptions.indexOf(index) == -1) || option === '' "
              v-model="selectedoptions"
              :value="index"
              v-on:click="selectOption($event, index)"
              />
              Correct answer
            </span>
            <ValidationProvider v-slot="{ errors }" rules="required">
              <textarea class="u-full-width" placeholder="Enter option" v-model="options[index]"></textarea>
              <span>{{errors[0]}}</span>
            </ValidationProvider>
          </label>
        </div>

        <div class="form-group">
          <button class="add-option-btn" @click="addOption" v-if="options.length < optionsMaxLength" >+</button>
          <button class="del-option-btn" v-if="options.length > optionsMinLength" @click="removeOption" >-</button>
        </div>

        <div class="form-group">
          <input type="checkbox" v-model="allow_multiple_answers" v-on:click="allowMultipleAns($event)">
          Allow selection of multiple answers
        </div>

        <div class="form-group">
          <label>author: </label>
          <ValidationProvider v-slot="{ errors }" rules="required">
            <input type="text" class="u-full-width" placeholder="Enter author" v-model="author">
            <span> {{errors[0]}} </span>
          </ValidationProvider>
        </div>

        <div class="form-group">
        <label>points: </label>
        <ValidationProvider v-slot="v" :rules="`between:${minPoint},${maxPoint}|required`">
          <input type="number" v-model="point" :min="minPoint" :max="maxPoint">
          <span> {{v.errors[0]}} </span>
        </ValidationProvider>
        </div>

        <div class="form-group">
        <label>Correct answer(s) explanation: </label>
        <textarea class="u-full-width" placeholder="Correct answer(s) explanation" v-model="explanation"></textarea>
        </div>

        <button class="add-quiz-btn" type="submit">Add Quiz</button>

    </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import RichTextEditor from '@/components/ui/RichTextEditor.vue';
import '@/util/validation';

const { mapActions, mapGetters } = createNamespacedHelpers('staging/quizzes');

export default {
  name: 'AddQuiz',
  components: {
    ValidationProvider,
    ValidationObserver,
    RichTextEditor,
  },
  data() {
    return {
      // data needed for business logic
      error: '',
      zero: 0,
      minanswersArrLength: 1,
      minNumOfoptions: 1,
      maxNumOfoptions: 4,
      maxSelectableOption: 1,
      optionsMinLength: 1,
      optionsMaxLength: 4,
      selectedoptions: [],
      minPoint: 1,
      maxPoint: 10,

      // data needed to construct the quiz for the api endpoint
      book_id: this.$route.params.id,
      question: '',
      options: [''],
      allow_multiple_answers: false,
      answers: [],
      author: '',
      point: '',
      explanation: '',

    };
  },
  methods: {
    ...mapActions(['addQuiz']),
    addOption(e) {
      e.preventDefault();
      this.options.push('');
    },
    removeOption(e) {
      e.preventDefault();
      const lastOptionsIndex = this.options.length - 1;
      const option = this.options[lastOptionsIndex];
      const optionIndex = this.options.indexOf(option);
      const indexInAnswersArr = this.answers.indexOf(optionIndex);
      const indexinSelectedOptionsArr = this.selectedoptions.indexOf(optionIndex);

      // check if the option being deleted exists in the answers array and the selected options array
      // then delete it from the answers and selected options array using it's index
      if ((indexInAnswersArr > -1) && (indexinSelectedOptionsArr > -1)) {
        this.selectedoptions.splice(indexinSelectedOptionsArr, 1);
        this.answers.splice(indexInAnswersArr, 1);
      }
      // delete the option from the options array
      this.options.pop();
    },
    selectOption(e, optionIndex) {
      if (e.target.checked) {
        this.answers.push(optionIndex);
        this.selectedoptions.push(optionIndex);
      } else {
        const indexInAnswersArr = this.answers.indexOf(optionIndex);
        const indexinSelectedOptionsArr = this.selectedoptions.indexOf(optionIndex);
        this.answers.splice(indexInAnswersArr, 1);
        this.selectedoptions.splice(indexinSelectedOptionsArr, 1);

        if (this.answers.length === this.minanswersArrLength) {
          this.error = '';
        }
      }
    },
    allowMultipleAns(event) {
      if (event.target.checked) {
        this.error = '';
        this.maxSelectableOption = this.maxNumOfoptions;
        this.allow_multiple_answers = true;
      } else {
        this.maxSelectableOption = this.minNumOfoptions;
        this.allow_multiple_answers = false;
        if (this.answers.length > this.zero) {
          this.error = 'Please Select only one Answer';
        }
      }
    },
    onSubmit() {
      this.validateForm();
    },
    validateForm() {
      if (!this.allow_multiple_answers) {
        if ((!this.answers.length) || (this.answers.length > this.minanswersArrLength)) {
          this.error = 'Please select only one option';
        } else {
          this.submit();
        }
      }

      if (this.allow_multiple_answers) {
        if ((!this.answers.length) || (this.answers.length <= this.minanswersArrLength)) {
          this.error = 'please more than one option';
        } else {
          this.submit();
        }
      }
    },
    submit() {
      const quizArray = [
        {
          book_id: this.$route.params.id,
          question: this.question,
          options: this.options,
          allow_multiple_answers: this.allow_multiple_answers,
          answers: this.answers,
          explanation: this.explanation,
          point: +(this.point),
          author: this.author,
        },
      ];

      const quiz = JSON.stringify(quizArray);
      this.addQuiz(quiz);
      this.$router.push('/quizzes');
    },
  },
  computed: {
    ...mapGetters(['errInfo', 'errored', 'loading']),
  },
};
</script>

<style lang='stylus' scoped>
  .add-quiz-btn, .add-option-btn, .del-option-btn
    margin-left: 1rem
    background-color: var(--color_primary)
    color: var(--color_on_primary)
</style>
